import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private readonly titleService: Title = inject(Title);
  private readonly commonService: CommonService = inject(CommonService);
  private readonly auth: Auth = inject(Auth);
  private readonly router: Router = inject(Router);
  public pwdIcon: string = 'visibility';
  public pwdType: string = 'password';
  public readonly form: FormGroup;
  constructor(private readonly fb: FormBuilder) {
    this.titleService.setTitle('Login');
    this.form = this._buildForm();
  }

  private _buildForm() {
    let form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      pwd: ['', [Validators.required]],
    });
    if (environment.connectToEmulator) {
      form = this.fb.group({
        email: ['mra.agent@mail.com', [Validators.required, Validators.email]],
        pwd: ['home@123', [Validators.required]],
      });
    }
    return form;
  }

  public get f() {
    return this.form.controls;
  }

  public onSignInClick() {
    this.form.markAllAsTouched();
    console.log('Error is : ', this.f['email'].errors);
    console.log('Error is : ', this.f['pwd'].errors);
    if (this.form.invalid) {
      this.commonService.showMsg('e', 'Error', 'Email and password required');
      return;
    }
    const email = this.f['email'].value;
    const pwd = this.f['pwd'].value;
    this.commonService.showLoader();
    signInWithEmailAndPassword(this.auth, email, pwd)
      .then(() => this.router.navigate(['/delivery-manager']))
      .catch((error) => {
        console.log('Error on sign in : ', error);
        const { code } = error;
        console.log('Error code is : ', code);
        let msg = 'Failed to login';
        if (code == 'auth/invalid-credential') {
          msg = 'Invalid email or password!';
          this.f['pwd'].setErrors({ invalid: true });
        }
        this.commonService.showMsg('error', 'Error', msg);
      })
      .finally(() => this.commonService.hideLoader());
  }
}
