import { Component, Inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { DatePipe } from '@angular/common';

interface DialogData {
  order: any;
}

@Component({
  selector: 'dialog-order-details',
  templateUrl: 'dialog-order-details.html',
  styleUrls: ['dialog-order-details.scss'],
  standalone: true,
  imports: [MatTabsModule, MatChipsModule, DatePipe],
})
export class DialogOrderDetails {
  public orders: any[] = [];
  public order: any = {};
  public cartItems: any[] = [];
  public orderTimestamps: any[] = [];
  public orderNotes: string = '';
  public discounts: any[] = [];
  public surcharges: any[] = [];
  constructor(
    public readonly dialogRef: DialogRef,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {
    this.order = this.data.order || {};
    const { cart = [], notes = [], discount = {}, surcharge = {} } = this.order;
    this.cartItems = cart;
    this.orderNotes = notes.join(', ');
    console.log('order is : ', this.order);
    const { discounts = [] } = discount;
    const { surcharges = [] } = surcharge;
    this.discounts = discounts;
    this.surcharges = surcharges;
  }
}
