import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {
  catchError,
  filter,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CommonService } from '../../services/common.service';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormsModule,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  private readonly authService: AuthService = inject(AuthService);
  private readonly commonService: CommonService = inject(CommonService);
  public req$: Observable<any> = of([]);
  public profile: any = null;
  public vendor: any = null;
  public readonly form: FormGroup;
  public imgUrl: string = '';
  constructor(private readonly fb: FormBuilder) {
    this.form = this._buildForm();
  }

  ngOnInit() {
    this._getProfile();
  }

  private _getProfile() {
    // Get the user's profile
    console.log('Getting profile');
    const url = '/api/cc-vendor/profile';
    this.commonService.showLoader();
    let req$: Observable<any> = this.authService
      .getData(url)
      .pipe(finalize(() => this.commonService.hideLoader()));
    req$ = req$.pipe(
      catchError((error) =>
        this.commonService.handleHttpError(
          error,
          'Error; Failed to get profile'
        )
      ),
      filter((res: any) => !!res)
    );
    req$ = req$.pipe(
      map((d) => d.data),
      tap((d) => {
        console.log('Profile:', d);
        const { user, vendor } = d;
        this.profile = user;
        this.imgUrl =
          this.profile.imgUrl || '../../../assets/profile-image.png';
        this.imgUrl =
          'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png';
        if (user) {
          this.form.patchValue({
            name: user.name,
            email: user.email,
          });
        }
        this.vendor = vendor;
      })
    );
    this.req$ = req$;
  }

  private _buildForm() {
    const form = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      email: [{ value: '', disabled: true }],
    });
    return form;
  }

  public get f() {
    return this.form.controls;
  }

  public onChangeInImage(event: any) {
    console.log('Image changed : ', event);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.imgUrl = reader.result as string;
    };
  }

  public onDeleteImg() {
    const conf = confirm('Are you sure you want to delete this image?');
    if (!conf) {
      return;
    }
    const url = '/api/cc-vendor/profile/delete-image';
    this.commonService.showLoader();
    let req$: Observable<any> = this.authService
      .postData(url, {})
      .pipe(finalize(() => this.commonService.hideLoader()));
    req$ = req$.pipe(
      catchError((error) =>
        this.commonService.handleHttpError(
          error,
          'Error; Failed to delete image'
        )
      ),
      filter((res: any) => res && true)
    );
    req$ = req$.pipe(
      tap((d) => {
        console.log('Deleted image:', d);
        this.imgUrl = '';
      })
    );
    this.req$ = req$;
  }

  public onUpdateClick() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.commonService.showSnackBar('Invalid form');
      return;
    }
    const url = '/api/cc-vendor/profile';
    const name = this.f['name'].value;
    const payload = { name };
    this.commonService.showLoader();
    let req$: Observable<any> = this.authService
      .putData(url, payload)
      .pipe(finalize(() => this.commonService.hideLoader()));
    req$ = req$.pipe(
      catchError((error) =>
        this.commonService.handleHttpError(
          error,
          'Error; Failed to update profile'
        )
      ),
      filter((res: any) => !!res)
    );
    this.req$ = req$.pipe(
      tap((d) => {
        console.log('Updated profile:', d);
        this.commonService.showSnackBar('Profile updated');
      })
    );
  }
}
