import { Injectable, inject } from '@angular/core';
import { Auth, authState, user, reload, User } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly auth = inject(Auth);
  private readonly authState$ = authState(this.auth);
  private readonly currentUser$ = user(this.auth);

  constructor(private readonly http: HttpClient) {}

  public get isAuthenticated$() {
    return this.authState$;
  }

  public get idToken() {
    return this.currentUser$;
  }

  public getData(url: string) {
    const URL = environment.FunctionURL + url;
    return this.http.get(URL);
  }

  public postData(url: string, data: any) {
    const URL = environment.FunctionURL + url;
    return this.http.post(URL, data);
  }

  public deleteData(url: string) {
    const URL = environment.FunctionURL + url;
    return this.http.delete(URL);
  }

  public putData(url: string, data: any) {
    const URL = environment.FunctionURL + url;
    return this.http.put(URL, data);
  }

  public reloadUser(user: User) {
    return reload(user);
  }
}
