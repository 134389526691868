import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { from, switchMap, take } from 'rxjs';
import { AuthService } from './services/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);

  return auth.idToken.pipe(
    take(1),
    switchMap((use) => {
      const newObs = from(
        use ? use.getIdToken() : new Promise((resolve, _) => resolve(null))
      );
      const obs = newObs.pipe(
        switchMap((y) => {
          if (y) {
            console.log('Token found');
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${y}`,
              },
            });
            return next(req);
          }
          return next(req);
        })
      );
      return obs;
    })
  );
};
