import { Component, inject, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { Title } from '@angular/platform-browser';
import {
  catchError,
  filter,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import {
  Firestore,
  collection,
  query,
  where,
  collectionData,
  orderBy,
  limit,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { DialogOrderDetails } from './dialog-order-details/dialog-order-details';
import { AuthService } from '../../services/auth.service';
import {
  ERROR_CODES_CUSTOM,
  FirebaseCollection,
  FirebaseDocFields,
  OrderMode,
  OrderStatus,
} from '../../constants/firebase-constants';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    MatTabsModule,
    MatBadgeModule,
    MatDialogModule,
    DialogOrderDetails,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss',
})
export class OrdersComponent implements OnInit {
  private readonly titleService: Title = inject(Title);
  private readonly commonService: CommonService = inject(CommonService);
  private readonly authService: AuthService = inject(AuthService);
  public ordersInRealTime$: Observable<any> = of([]);
  private readonly firestore: Firestore = inject(Firestore);
  private readonly router: Router = inject(Router);

  public readonly orderModes: any[] = [OrderMode.DELIVERY, OrderMode.TAKE_AWAY];
  public selectedOrderMode: FormControl = new FormControl(OrderMode.DELIVERY);
  public selectedBranch: FormControl = new FormControl('');
  public req$: Observable<any> = of([]);
  public selectedStatus: string = OrderStatus.NEW;
  public vendorId: string = '';
  public tabLabels: any[] = [
    { label: 'New', value: OrderStatus.NEW, count: 0 },
    { label: 'Ready', value: OrderStatus.READY, count: 0 },
    {
      label: 'Out for delivery',
      value: OrderStatus.OUT_FOR_DELIVERY,
      count: 0,
    },
    {
      label: 'Delivered',
      value: OrderStatus.DELIVERED,
      count: 0,
    },
  ];

  public tabLabelsTakeAway: any[] = [
    { label: 'New', value: OrderStatus.NEW, count: 0 },
    { label: 'Ready', value: OrderStatus.READY, count: 0 },
    {
      label: 'Delivered',
      value: OrderStatus.DELIVERED,
      count: 0,
    },
  ];

  public readonly date$: Observable<Date> = of(new Date());
  public newOrders: any[] = [];
  public readyOrders: any[] = [];
  public outForDeliveryOrders: any[] = [];
  public deliveredOrders: any[] = [];
  public branches: any[] = [];

  public newOrdersTakeAway: any[] = [];
  public readyOrdersTakeAway: any[] = [];
  public deliveredOrdersTakeAway: any[] = [];
  public permission$: Observable<any> = of([]);
  public onInit: boolean = true;

  constructor(private readonly dialog: MatDialog) {
    this.orderModes = this.orderModes.map((mode) => {
      return {
        label: (
          mode.charAt(0).toUpperCase() + mode.toLowerCase().slice(1)
        ).replace(/_/g, ' '),
        value: mode,
      };
    });
    this.titleService.setTitle('orders|Vendor');
    this.date$ = timer(0, 1000).pipe(
      map(() => new Date()),
      shareReplay()
    );
  }

  ngOnInit() {
    this.permission$ = this._checkPermission();
  }

  private _getOrdersInRealTime(params: any) {
    // Get orders from the server in real-time
    // Get vendor ID and branch ID from the params
    let { cb } = params || {};
    console.log('callback is : ', cb);
    const branchId = this.selectedBranch.value;
    console.log(
      'Relatime vendor id and branch id --> ',
      this.vendorId,
      branchId
    );
    const collectionPath = `${FirebaseCollection.VENDORS}/${this.vendorId}/${FirebaseCollection.ORDER_REFS}`;
    console.log('Realtime collectionPath --> ', collectionPath);
    // Define the query
    let ordersQuery: any = null;
    const orderStatus: string[] = [
      OrderStatus.NEW,
      OrderStatus.READY,
      OrderStatus.OUT_FOR_DELIVERY,
      OrderStatus.DELIVERED,
    ];
    console.log('order status values : ', orderStatus);
    const ALL_BRANCHES = 'all';
    if (branchId == ALL_BRANCHES) {
      console.log('Getting all orders with branch id all ...');
      ordersQuery = query(
        collection(this.firestore, collectionPath),
        where(FirebaseDocFields.STATUS, 'in', orderStatus), // Exclude canceled orders
        orderBy(FirebaseDocFields.ASSIGNED_AT, 'desc'), // Sort by assigned_at in descending order
        limit(1) // Limit to 1 order
      );
    } else {
      console.log('Getting orders with branch id ', branchId, ' ...');
      ordersQuery = query(
        collection(this.firestore, collectionPath),
        where(FirebaseDocFields.STATUS, 'in', orderStatus), // Exclude canceled orders
        where(FirebaseDocFields.BRANCH_ID, '==', branchId),
        orderBy(FirebaseDocFields.ASSIGNED_AT, 'desc'), // Sort by assigned_at in descending order
        limit(1) // Limit to 1 order
      );
    }

    // Get orders in real-time
    this.ordersInRealTime$ = collectionData(ordersQuery, {
      idField: 'id',
    }).pipe(
      switchMap((d: any[]) => {
        console.log('Realtime orders zeroth element --> ', d[0]);
        if (d.length > 0) {
          const order = d[0];
          const { status, id, order_mode } = order;
          // Remove from all orders
          if (order_mode == OrderMode.DELIVERY) {
            let index = this.newOrders.findIndex((e) => e.id == id);
            if (index > -1) {
              this.newOrders.splice(index, 1);
            }
            index = this.readyOrders.findIndex((e) => e.id == id);
            if (index > -1) {
              this.readyOrders.splice(index, 1);
            }
            index = this.outForDeliveryOrders.findIndex((e) => e.id == id);
            if (index > -1) {
              this.outForDeliveryOrders.splice(index, 1);
            }
            index = this.deliveredOrders.findIndex((e) => e.id == id);
            if (index > -1) {
              this.deliveredOrders.splice(index, 1);
            }
          } else if (order_mode == OrderMode.TAKE_AWAY) {
            let index = this.newOrdersTakeAway.findIndex((e) => e.id == id);
            if (index > -1) {
              this.newOrdersTakeAway.splice(index, 1);
            }
            index = this.readyOrdersTakeAway.findIndex((e) => e.id == id);
            if (index > -1) {
              this.readyOrdersTakeAway.splice(index, 1);
            }
            index = this.deliveredOrdersTakeAway.findIndex((e) => e.id == id);
            if (index > -1) {
              this.deliveredOrdersTakeAway.splice(index, 1);
            }
          }
          if (cb) {
            console.log('calling callback : ', cb);
            const x = cb();
            cb = null;
            return x;
          } else {
            console.log(
              'Get orders of else part called with status : ',
              status
            );
            return this._getOrders({
              status,
              orderId: id,
              getCount: true,
            });
          }
        } else {
          if (cb) {
            const p = cb();
            cb = null;
            return p;
          }
        }
        return of([]);
      })
    );
  }

  public openOrderDetailsDialog(order: any) {
    const dialogRef = this.dialog.open(DialogOrderDetails, {
      width: '80vw',
      data: { order },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed with result : ', result);
    });
  }

  private _getOrders(fnParams: any = {}) {
    console.log('Getting orders...', fnParams);
    // Degfine the query body
    const params = {
      status: this.selectedStatus,
      branch_id: this.selectedBranch.value,
      order_mode: this.selectedOrderMode.value,
      ...fnParams,
    };
    const url = '/api/cc-vendor/orders'; // URL to get orders
    this.commonService.showLoader(); // Show loader
    let req$ = this.authService
      .postData(url, params) // Get orders
      .pipe(finalize(() => this.commonService.hideLoader())); // Hide loader on completion
    req$ = req$.pipe(
      catchError((e) => {
        const { code } = e;
        if (code == ERROR_CODES_CUSTOM.PERMISSION_DENIED) {
          this.router.navigate(['/not_found']);
        }
        return this.commonService.handleHttpError(
          e,
          'Error; Failed to get orders'
        );
      }),
      filter((e: any) => !!e)
    );
    req$ = req$.pipe(
      map((e: any) => e.data),
      tap((d: any) => {
        console.log('order data: ', d);
        const { items = [], order_count, params } = d;
        if (order_count) {
          if (params) {
            if (params.order_mode == OrderMode.DELIVERY) {
              console.log('order_count of delivery mode --> ', order_count);
              this.tabLabels = this.tabLabels.map((e) => {
                let { value } = e;
                value =
                  typeof value == 'string'
                    ? value.replace(/ /g, '_').toLowerCase()
                    : '';
                const count = order_count[value.toLowerCase()]
                  ? order_count[value.toLowerCase()]
                  : 0;
                return { ...e, count };
              }); // Update tab labels
              console.log('this.tabLabels --> ', this.tabLabels);
            } else if (params.order_mode == OrderMode.TAKE_AWAY) {
              console.log('order_count of take away mode --> ', order_count);
              this.tabLabelsTakeAway = this.tabLabelsTakeAway.map((e) => {
                let { value } = e;
                value =
                  typeof value == 'string'
                    ? value.replace(/ /g, '_').toLowerCase()
                    : '';
                const count = order_count[value.toLowerCase()]
                  ? order_count[value.toLowerCase()]
                  : 0;
                return { ...e, count };
              }); // Update tab labels
              console.log(
                'this.takeAwayTabLabels --> ',
                this.tabLabelsTakeAway
              );
            }
          }
        }

        if (items && items.length == 0) {
          // Show snackbar if no orders found
          // showMsg have some styling issue
          this.commonService.showSnackBar('No more orders found');
        }

        if (params) {
          const { status, order_mode } = params;
          console.log('order mode is : ', order_mode);
          if (order_mode == OrderMode.DELIVERY) {
            console.log('Delivery order mode found');
            if (status == OrderStatus.NEW) {
              this._processNewOrders(items);
            } else if (status == OrderStatus.READY) {
              this._processReadyOrders(items);
            } else if (status == OrderStatus.OUT_FOR_DELIVERY) {
              this._processOutForDeliveryOrders(items);
            } else if (status == OrderStatus.DELIVERED) {
              this._processDeliveredOrders(items);
            }
          } else if (order_mode == OrderMode.TAKE_AWAY) {
            console.log('Take away order mode found');
            if (status == OrderStatus.NEW) {
              this._processNewOrderTakeAway(items);
            } else if (status == OrderStatus.READY) {
              this._processReadyOrderTakeAway(items);
            } else if (status == OrderStatus.DELIVERED) {
              this._processDeliveredOrderTakeAway(items);
            }
          }
        }
      })
    );
    return req$;
  }

  private _checkPermission() {
    const url = '/api/cc-vendor/orders/permission'; // URL to get orders
    this.commonService.showLoader(); // Show loader
    let req$ = this.authService
      .postData(url, {}) // Get orders
      .pipe(finalize(() => this.commonService.hideLoader())); // Hide loader on completion
    req$ = req$.pipe(
      catchError((e) => {
        const { code } = e;
        if (code == ERROR_CODES_CUSTOM.PERMISSION_DENIED) {
          this.router.navigate(['/not_found']);
        }
        return this.commonService.handleHttpError(
          e,
          'Error; Failed to validate permission'
        );
      }),
      filter((e: any) => !!e)
    );
    req$ = req$.pipe(
      map((e: any) => e.data),
      tap((d: any) => {
        console.log('Permission data ', d);
        const { hasPermission, branches, vendor_id, user_branch_id } = d;
        if (hasPermission) {
          this.branches = branches;
          this.selectedBranch.setValue(user_branch_id);
          this.vendorId = vendor_id;
          // now call get orders
          const cb = () => {
            return this._getOrders({
              status: OrderStatus.NEW,
              orderId: null,
              getCount: true,
            });
          };
          this._getOrdersInRealTime({ cb });
        } else {
          this.commonService.showMsg(
            'error',
            'Permission Denied',
            'You do not have permission to access this page'
          );
          setTimeout(() => {
            this.router.navigate(['/not_found']);
          }, 500);
        }
      })
    );
    return req$;
  }

  private _processDeliveredOrderTakeAway(orders: any[]) {
    console.log('Delivered orders TAKE AWAY --> ', orders);
    for (const order of orders) {
      const { id, created_at_as_date } = order;
      const elapsed_time =
        this.commonService.getElapsedTime(created_at_as_date);
      const obj = { ...order, elapsed_time };
      const index = this.deliveredOrdersTakeAway.findIndex((e) => e.id == id);
      if (index > -1) {
        this.deliveredOrdersTakeAway[index] = obj; // Update existing order
      } else {
        this.deliveredOrdersTakeAway.unshift(obj); // Add new order
      }
    }
    console.log(
      'Delivered orders global of TAKE AWAY :: ',
      this.deliveredOrdersTakeAway
    );
  }

  private _processReadyOrderTakeAway(orders: any[]) {
    // Process new orders
    console.log('ready orders TAKE AWAY --> ', orders);
    for (const order of orders) {
      const { id, created_at_as_date } = order;
      const elapsed_time =
        this.commonService.getElapsedTime(created_at_as_date);
      const obj = { ...order, elapsed_time };
      const index = this.readyOrdersTakeAway.findIndex((e) => e.id == id);
      if (index > -1) {
        this.readyOrdersTakeAway[index] = obj; // Update existing order
      } else {
        this.readyOrdersTakeAway.unshift(obj); // Add new order
      }
    }
  }

  private _processNewOrderTakeAway(orders: any[]) {
    // Process new orders
    console.log('new orders TAKE AWAY --> ', orders);
    for (const order of orders) {
      console.log('order --> ', order);
      const { id, created_at_as_date } = order;
      const elapsed_time =
        this.commonService.getElapsedTime(created_at_as_date);
      const obj = { ...order, elapsed_time };
      const index = this.newOrdersTakeAway.findIndex((e) => e.id == id);
      if (index > -1) {
        this.newOrdersTakeAway[index] = obj; // Update existing order
      } else {
        this.newOrdersTakeAway.unshift(obj); // Add new order
      }
    }
  }

  public onTabChange(event: MatTabChangeEvent, type: number = 1) {
    console.log('event --> ', event);
    const { index } = event;
    if (type == 1) {
      if (index === 0) {
        this.selectedStatus = OrderStatus.NEW;
        if (this.newOrders.length > 0) {
          return;
        }
      } else if (index === 1) {
        this.selectedStatus = OrderStatus.READY;
        if (this.readyOrders.length > 0) {
          return;
        }
      } else if (index === 2) {
        this.selectedStatus = OrderStatus.OUT_FOR_DELIVERY;
        if (this.outForDeliveryOrders.length > 0) {
          return;
        }
      } else if (index === 3) {
        this.selectedStatus = OrderStatus.DELIVERED;
        if (this.deliveredOrders.length > 0) {
          return;
        }
      } else return;
    } else if (type == 2) {
      if (index === 0) {
        this.selectedStatus = OrderStatus.NEW;
        if (this.newOrdersTakeAway.length > 0) {
          return;
        }
      } else if (index === 1) {
        this.selectedStatus = OrderStatus.READY;
        if (this.readyOrdersTakeAway.length > 0) {
          return;
        }
      } else if (index === 2) {
        this.selectedStatus = OrderStatus.DELIVERED;
        if (this.deliveredOrdersTakeAway.length > 0) {
          return;
        }
      } else return;
    }

    this.req$ = this._getOrders();
  }

  private _processNewOrders(orders: any[]) {
    // Process new orders
    console.log('new orders home --> ', orders);
    for (const order of orders) {
      console.log('order --> ', order);
      const { id, created_at_as_date, order_mode } = order;
      if (order_mode != OrderMode.DELIVERY) continue;
      const elapsed_time =
        this.commonService.getElapsedTime(created_at_as_date);
      const obj = { ...order, elapsed_time };
      const index = this.newOrders.findIndex((e) => e.id == id);
      if (index > -1) {
        this.newOrders[index] = obj; // Update existing order
      } else {
        this.newOrders.unshift(obj); // Add new order
      }
    }
  }

  private _processReadyOrders(orders: any[]) {
    // Process new orders
    console.log('ready orders --> ', orders);
    for (const order of orders) {
      const { id, created_at_as_date, order_mode } = order;
      if (order_mode != OrderMode.DELIVERY) continue;
      const elapsed_time =
        this.commonService.getElapsedTime(created_at_as_date);
      const obj = { ...order, elapsed_time };
      const index = this.readyOrders.findIndex((e) => e.id == id);
      if (index > -1) {
        this.readyOrders[index] = obj; // Update existing order
      } else {
        this.readyOrders.unshift(obj); // Add new order
      }
    }
  }

  private _processOutForDeliveryOrders(orders: any[]) {
    console.log('out for delivery orders --> ', orders);
    for (const order of orders) {
      const { id, created_at_as_date, order_mode } = order;
      if (order_mode != OrderMode.DELIVERY) continue;
      const elapsed_time =
        this.commonService.getElapsedTime(created_at_as_date);
      const obj = { ...order, elapsed_time };
      const index = this.outForDeliveryOrders.findIndex((e) => e.id == id);
      if (index > -1) {
        this.outForDeliveryOrders[index] = obj; // Update existing order
      } else {
        this.outForDeliveryOrders.unshift(obj); // Add new order
      }
    }
    console.log('outForDeliveryOrders global --> ', this.outForDeliveryOrders);
  }

  private _processDeliveredOrders(orders: any[]) {
    console.log('Delivered orders --> ', orders);
    for (const order of orders) {
      const { id, created_at_as_date, order_mode } = order;
      if (order_mode != OrderMode.DELIVERY) continue;
      const elapsed_time =
        this.commonService.getElapsedTime(created_at_as_date);
      const obj = { ...order, elapsed_time };
      const index = this.deliveredOrders.findIndex((e) => e.id == id);
      if (index > -1) {
        this.deliveredOrders[index] = obj; // Update existing order
      } else {
        this.deliveredOrders.unshift(obj); // Add new order
      }
    }
    console.log('Delivered orders global --> ', this.deliveredOrders);
  }

  public onStartAfterClick(item: any, type: number = 1) {
    console.log('current tab --> ', item);
    const { count } = item;

    console.log('count --> ', count);

    let startAfter = null;
    if (type == 1) {
      if (item.value == OrderStatus.NEW) {
        const lastItem = this.newOrders[this.newOrders.length - 1];
        if (this.newOrders.length <= count && lastItem) {
          startAfter = lastItem.startAfter;
        }
      } else if (item.value == OrderStatus.READY) {
        const lastItem = this.readyOrders[this.readyOrders.length - 1];
        if (this.readyOrders.length <= count && lastItem) {
          startAfter = lastItem.startAfter;
        }
      } else if (item.value == OrderStatus.OUT_FOR_DELIVERY) {
        const lastItem =
          this.outForDeliveryOrders[this.outForDeliveryOrders.length - 1];
        if (this.outForDeliveryOrders.length <= count && lastItem) {
          startAfter = lastItem.startAfter;
        }
      } else if (item.value == OrderStatus.DELIVERED) {
        const lastItem = this.deliveredOrders[this.deliveredOrders.length - 1];
        if (this.deliveredOrders.length <= count && lastItem) {
          startAfter = lastItem.startAfter;
        }
      }
    } else if (type == 2) {
      if (item.value == OrderStatus.NEW) {
        const lastItem =
          this.newOrdersTakeAway[this.newOrdersTakeAway.length - 1];
        if (this.newOrdersTakeAway.length <= count && lastItem) {
          startAfter = lastItem.startAfter;
        }
      } else if (item.value == OrderStatus.READY) {
        const lastItem =
          this.readyOrdersTakeAway[this.readyOrdersTakeAway.length - 1];
        if (this.readyOrdersTakeAway.length <= count && lastItem) {
          startAfter = lastItem.startAfter;
        }
      } else if (item.value == OrderStatus.DELIVERED) {
        const lastItem =
          this.deliveredOrdersTakeAway[this.deliveredOrdersTakeAway.length - 1];
        if (this.deliveredOrdersTakeAway.length <= count && lastItem) {
          startAfter = lastItem.startAfter;
        }
      }
    }

    if (startAfter) {
      this.req$ = this._getOrders({ start_after: startAfter });
    }
  }

  public onStatusChangeClick(order: any, type: number = 1) {
    console.log('order --> ', order);
    const { id } = order;
    const url = '/api/cc-vendor/order/update-status';
    const params = { order_id: id };
    this.commonService.showLoader();
    let req$ = this.authService
      .postData(url, params)
      .pipe(finalize(() => this.commonService.hideLoader()));
    req$ = req$.pipe(
      catchError((e) => {
        return this.commonService.handleHttpError(
          e,
          'Error; Failed to update order status'
        );
      }),
      filter((e: any) => !!e)
    );
    this.req$ = req$.pipe(
      map((e: any) => e.data),
      tap((d: any) => {
        this.commonService.showMsg(
          'success',
          'Message',
          'Order status updated successfully'
        );
        console.log('updated data --> ', d);
        const { old_status } = d;
        if (type == 1) {
          if (old_status == OrderStatus.NEW) {
            const index = this.newOrders.findIndex((e) => e.id == id);
            if (index > -1) {
              this.newOrders.splice(index, 1);
            }
          } else if (old_status == OrderStatus.READY) {
            const index = this.readyOrders.findIndex((e) => e.id == id);
            if (index > -1) {
              this.readyOrders.splice(index, 1);
            }
          } else if (old_status == OrderStatus.OUT_FOR_DELIVERY) {
            const index = this.outForDeliveryOrders.findIndex(
              (e) => e.id == id
            );
            if (index > -1) {
              this.outForDeliveryOrders.splice(index, 1);
            }
          }
        } else if (type == 2) {
          if (old_status == OrderStatus.NEW) {
            const index = this.newOrdersTakeAway.findIndex((e) => e.id == id);
            if (index > -1) {
              this.newOrdersTakeAway.splice(index, 1);
            }
          } else if (old_status == OrderStatus.READY) {
            const index = this.readyOrdersTakeAway.findIndex((e) => e.id == id);
            if (index > -1) {
              this.readyOrdersTakeAway.splice(index, 1);
            }
          }
        }
      })
    );
  }

  public onOrderModeChange($event: any) {
    console.log('on order mode change :: ', $event);
    const { value } = $event;
    this.selectedStatus = OrderStatus.NEW;
    if (value == OrderMode.TAKE_AWAY) {
      if (this.newOrdersTakeAway.length == 0) {
        this.req$ = this._getOrders({ getCount: true });
      }
    } else if (value == OrderMode.DELIVERY) {
      if (this.newOrders.length == 0) {
        this.req$ = this._getOrders({ getCount: true });
      }
    }
  }

  public onChangeInBranch($event: MatSelectChange) {
    console.log('on change in branch :: ', $event);
    this._resetParams();
    const cb = () => {
      return this._getOrders({
        orderId: null,
        getCount: true,
      });
    };
    this._getOrdersInRealTime({ cb });
  }

  private _resetParams() {
    this.newOrders = [];
    this.outForDeliveryOrders = [];
    this.readyOrders = [];
    this.deliveredOrders = [];
    this.newOrdersTakeAway = [];
    this.readyOrdersTakeAway = [];
    this.deliveredOrdersTakeAway = [];
    this.selectedStatus = OrderStatus.NEW;
  }
}
