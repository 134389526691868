import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-menu-items',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    NgClass,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatPaginatorModule,
    ConfirmDialogModule,
  ],
  templateUrl: './menu-items.component.html',
  styleUrl: './menu-items.component.scss',
  providers: [ConfirmationService],
})
export class MenuItemsComponent {
  private readonly titleService: Title = inject(Title);
  private readonly authService: AuthService = inject(AuthService);
  private readonly commonService: CommonService = inject(CommonService);
  public counterText: string = '0 Menu items';
  public req$: Observable<any> = of([]);
  public branches: any[] = [];
  public menuItemsObj: any = {
    items: [],
    count: 0,
    displayItems: [],
  };
  public branchId: FormControl = new FormControl('');
  public searchControl: FormControl = new FormControl('');
  private readonly confirmationService: ConfirmationService =
    inject(ConfirmationService);
  constructor() {
    this.titleService.setTitle('Menu items');
    this.req$ = this._getMenuItems();
    this.searchControl.valueChanges
      .pipe(
        map((value) => (typeof value === 'string' ? value : '')),
        map((value) => {
          if (!value) {
            this.menuItemsObj.displayItems = this.menuItemsObj.items.slice(
              0,
              10
            );
            this.counterText = `${this.menuItemsObj.items.length} Menu items`;
            return;
          }
          this.menuItemsObj.displayItems = this.menuItemsObj.items.filter(
            (item: any) => item.name.toLowerCase().includes(value.toLowerCase())
          );
          this.counterText = `${this.menuItemsObj.displayItems.length} Menu items`;
        })
      )
      .subscribe();
  }

  private _getMenuItems(params: any = {}): Observable<any> {
    console.log('Get menu items...');
    // Get menu items
    const url = '/api/cc-vendor/menu-items';
    // Payload for getting menu items
    // color code: #ff7600
    // show loader
    this.commonService.showLoader();
    const payload: any = {
      branch_id: this.branchId.value, // branch_id
      ...params, // start_after, avaMenuItems
    };
    let req$ = this.authService.postData(url, payload).pipe(
      finalize(() => this.commonService.hideLoader()), // hide loader
      catchError(
        (e) =>
          this.commonService.handleHttpError(
            e,
            'Error; Failed to get menu items'
          ) // handle error
      ),
      filter((e: any) => !!e) //  Filter out falsy values
    );
    req$ = req$.pipe(
      map((d) => d.data),
      tap((d) => {
        const { items, count, branches, selectedBranch } = d || {};
        if (branches) {
          this.branches = branches;
        }
        this.branchId.setValue(selectedBranch);
        this.menuItemsObj.count = this.menuItemsObj.count || count; // count
        this.counterText = `${this.menuItemsObj.count} Menu items`; // count text
        this.menuItemsObj.items = items; // set menu items
        this.menuItemsObj.displayItems = this.menuItemsObj.items.slice(0, 10); //  set display items
      })
    );
    return req$;
  }

  public onLoadMoreClick() {
    // Load more menu items
    const { startAfter } = this.menuItemsObj;
    if (!startAfter) {
      return;
    }
    const params = {
      start_after: startAfter,
      avaMenuItems: this.menuItemsObj.menuItemsAvailable,
    };
    this.req$ = this._getMenuItems(params);
  }

  public onChangeInMenuStatus(event: any, item: any) {
    // Change menu status
    event.preventDefault();
    const msg = `Are you sure that you want to ${
      item.is_disabled ? 'enable' : 'disable'
    } this menu item?`;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: msg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        const { id } = item;
        const url = '/api/cc-vendor/menu-item-status';
        const payload = {
          menu_item_id: id,
          is_disabled: !item.is_disabled,
          branch_id: this.branchId.value,
        };
        this.commonService.showLoader();
        const req$ = this.authService.postData(url, payload).pipe(
          finalize(() => this.commonService.hideLoader()),
          catchError(
            (e) =>
              this.commonService.handleHttpError(
                e,
                'Error; Failed to change menu status'
              ) // handle error
          ),
          filter((e: any) => !!e)
        );
        this.req$ = req$.pipe(
          tap((d) => {
            const { msg, data } = d;
            const { is_disabled } = data;
            this.commonService.showMsg(
              's',
              'Success',
              msg || 'Menu status changed successfully'
            );
            let index = this.menuItemsObj.items.findIndex(
              (e: any) => e.id == id
            );
            if (index > -1) {
              this.menuItemsObj.items[index].is_disabled = is_disabled;
            }
            index = this.menuItemsObj.displayItems.findIndex(
              (e: any) => e.id == id
            );
            if (index > -1) {
              this.menuItemsObj.displayItems[index].is_disabled = is_disabled;
            }
          })
        );
      },
      reject: () => null,
    });
  }

  public onPageChange(event: any) {
    // Change page
    const { pageIndex } = event;
    this.menuItemsObj.displayItems = this.menuItemsObj.items.slice(
      pageIndex * 10,
      (pageIndex + 1) * 10
    );
  }

  public onChangeInBranch(event$: MatSelectChange) {
    console.log('Branch changed : ', event$);
    const { value } = event$;
    this.req$ = this._getMenuItems({ branch_id: value });
  }
}
