<div class="container-fluid">
    @if (branches && branches.length>0) {
    <div class="row">
        <div class="col-12 d-flex justify-content-end">
            <mat-form-field appearance="fill">
                <mat-label>Branches</mat-label>
                <mat-select (selectionChange)="onChangeInBranch($event)" [formControl]="branchId">
                    @for (topping of branches; track topping) {
                    <mat-option [value]="topping.id">{{topping.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    }
    <div class="row">
        <div class="col-12 offset-md-3 col-md-6">
            <div class="card border-0 mat-elevation-z2">
                <div class="card-header bg-white" style="padding: 20px 15px;">
                    <span class="custom-card-title"> Menu items </span>
                    <span class="custom-badge">{{ counterText }}</span>
                    <p class="custom-desc">Keep track of all menu items in one place</p>
                </div>
                <div style="padding: 20px 15px;"
                    class="card-header bg-white d-flex d-flex align-items-center justify-content-end position-relative">
                    <div style="max-width: 45%;width:45%;">
                        <input type="text" [formControl]="searchControl" class="form-control form-control-md"
                            placeholder="Search for menu items...">
                    </div>
                </div>
                @if (menuItemsObj.displayItems.length==0) {
                <div class="card-body" style="min-height: auto;height: 500px;">
                    <div class="empty-div">
                        <div>
                            <div class="empty-icon">
                                <div>
                                    <span class="material-icons">search</span>
                                </div>
                            </div>
                            <div class="empty-text">
                                <p>No menu items found</p>
                                <h3>Make sure the filter is correct or try adding a new menu item</h3>
                            </div>
                            <div class="empty-btn">
                                <button type="button" class="btn btn-md clr">
                                    <span>Clear search</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                }@else {
                <div class="card-body p-0" style="max-height: 635px;overflow: auto;">
                    <div class="table-responsive">
                        <table class="table custom-table mb-0">
                            <thead>
                                <tr>
                                    <th>Item name</th>
                                    <th class="text-center">Has options?</th>
                                    <th class="text-center">Price</th>
                                    <th class="text-center">Available?</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for (item of menuItemsObj.displayItems; track $index) {
                                <tr>
                                    <td>{{ item.name }}</td>
                                    <td class="text-center"> <span class="material-icons"
                                            [ngClass]="item.has_options?'text-success':'text-secondary' "> {{
                                            item.has_options?'check_circle':'cancel' }} </span>
                                    </td>
                                    <td class="text-center">{{ item.price }}</td>
                                    <td class="text-center">
                                        <label class="switch">
                                            <input [checked]="!item.is_disabled"
                                                (click)="onChangeInMenuStatus($event,item)" type="checkbox">
                                            <span class="slider round"></span>
                                        </label>

                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                }

                <div class="card-footer bg-white">
                    <div class="d-flex justify-content-center align-items-center">
                        <mat-paginator (page)="onPageChange($event)"
                            [length]="menuItemsObj && menuItemsObj.items.length" [pageSize]="10">
                        </mat-paginator>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <hr class="border-0">
    <hr class="border-0">
</div>

@if (req$|async) {}
<p-confirmDialog></p-confirmDialog>