<div class="container-fluid">
    <div class="row">
        <div class="col-5 d-flex justify-content-start">
            <h2 style="color: var(--bs-info-text-emphasis);"> {{date$|async|date:'medium'}} </h2>
        </div>
        <div class="col-3 d-flex justify-content-center align-items-center">
            <mat-form-field class="w-100">
                <mat-label>Order mode</mat-label>
                <mat-select (selectionChange)="onOrderModeChange($event)" [formControl]="selectedOrderMode">
                    @for (food of orderModes; track food) {
                    <mat-option [value]="food.value">{{food.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-1"></div>
        @if (branches && branches.length>0) {
        <div class="col-3 d-flex justify-content-end align-items-center">
            <mat-form-field class="w-100">
                <mat-label>Branches</mat-label>
                <mat-select (selectionChange)="onChangeInBranch($event)" [formControl]="selectedBranch">
                    @for (food of branches; track food) {
                    <mat-option [value]="food.id">{{food.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        }
    </div>
    <hr class="border-0">
    <div class="row">
        <div class="col-12">
            <div class="border-0 mat-elevation-z1 bg-white px-0 pb-1 pt-2" style="min-height: 600px;">
                @if (selectedOrderMode.value =='DELIVERY') {
                <mat-tab-group (selectedTabChange)="onTabChange($event)">
                    @for (item of tabLabels; track $index) {
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span [matBadge]="item.count" matBadgeOverlap="false">{{item.label}}</span>
                        </ng-template>
                        @if ( item.value=='NEW' ){
                        <div class="row">
                            <div class="col-12">
                                @if (newOrders.length ==0) {
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    style="min-height: 600px;">
                                    <div>
                                        <img src="../../../assets/no-order.svg" alt="icon">
                                    </div>
                                    <div>
                                        <h3 class="text-muted">No new orders</h3>
                                    </div>
                                </div>
                                }@else {
                                <table class="table order-table mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                Order No
                                            </th>
                                            <th>
                                                Created at
                                            </th>
                                            <th>
                                                Elapsed time
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (item of newOrders; track $index) {
                                        <tr>
                                            <td>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-items-center" style="column-gap: 5px;">
                                                        <span>{{item.order_number}}</span>
                                                        @if (item.updated_by ) {
                                                        <span class="ml-3 badge rounded-pill bg-danger">Modified</span>

                                                        }
                                                    </div>
                                                    <span style="cursor: pointer;"
                                                        (click)="openOrderDetailsDialog(item)">click here to get
                                                        details...</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex flex-column" style="gap: 10px;">
                                                    <div>
                                                        <span>{{item.created_at_as_date|date:'medium'}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    style="color: var(--bs-danger-text-emphasis);">{{item.elapsed_time|async}}</span>
                                            </td>
                                            <td>
                                                @if (item.customer) {
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">person</span>
                                                    <span
                                                        style="color: var(--bs-info-text-emphasis);">{{item.customer.name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">phone</span>
                                                    <span>{{item.customer.phone}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="gap: 5px;">
                                                    <span class="material-icons">place</span>
                                                    <span>{{item.address_as_string||''}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="gap: 5px;">
                                                    <span class="material-icons">map</span>
                                                    <span>{{item.branch.name}}</span>
                                                </div>
                                                }
                                            </td>
                                            <td class="fw-bolder">
                                                {{item.total_as_string}}
                                            </td>
                                            <td class="text-end">
                                                <button (click)="onStatusChangeClick(item)"
                                                    class="btn btn-sm btn-outline-success" type="button">Ready?</button>
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <table class="table table-borderless w-100 m-0">
                                    <tbody>
                                        <tr>
                                            <td class="w-100 text-center">
                                                <button type="button" (click)="onStartAfterClick(item)"
                                                    class="btn btn-sm btn-outline-secondary">Load
                                                    more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        } @else if ( item.value == 'READY') {
                        <div class="row">
                            <div class="col-12">
                                @if (readyOrders.length == 0) {
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    style="min-height: 600px;">
                                    <div>
                                        <img src="../../../assets/no-order.svg" alt="icon">
                                    </div>
                                    <div>
                                        <h3 class="text-muted">No ready orders found!</h3>
                                    </div>
                                </div>
                                }@else {
                                <table class="table order-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Order No
                                            </th>
                                            <th>
                                                Created at
                                            </th>
                                            <th>
                                                Elapsed time
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (item of readyOrders; track $index) {
                                        <tr>
                                            <td>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-items-center" style="column-gap: 5px;">
                                                        <span>{{item.order_number}}</span>
                                                        @if (item.updated_by ) {
                                                        <span class="ml-3 badge rounded-pill bg-danger">Modified</span>
                                                        }
                                                    </div>
                                                    <span style="cursor: pointer;"
                                                        (click)="openOrderDetailsDialog(item)">click here to get
                                                        details...</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex flex-column" style="gap: 10px;">
                                                    <div>
                                                        <span>{{item.created_at_as_date|date:'medium'}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    style="color: var(--bs-danger-text-emphasis);">{{item.elapsed_time|async}}</span>
                                            </td>
                                            <td>
                                                @if (item.customer) {
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">person</span>
                                                    <span
                                                        style="color: var(--bs-info-text-emphasis);">{{item.customer.name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">phone</span>
                                                    <span>{{item.customer.phone}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="gap: 5px;">
                                                    <span class="material-icons">place</span>
                                                    <span>{{item.address_as_string}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="gap: 5px;">
                                                    <span class="material-icons">map</span>
                                                    <span>{{item.branch.name}}</span>
                                                </div>
                                                }
                                            </td>
                                            <td class="fw-bolder">
                                                {{item.total_as_string}}
                                            </td>
                                            <td class="text-end">
                                                <button (click)="onStatusChangeClick(item)"
                                                    class="btn btn-sm btn-outline-success" type="button"> Out
                                                    for delivery? </button>
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <table class="table table-borderless w-100 m-0">
                                    <tbody>
                                        <tr>
                                            <td class="w-100 text-center">
                                                <button type="button" (click)="onStartAfterClick(item)"
                                                    class="btn btn-sm btn-outline-secondary">Load
                                                    more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        }@else if ( item.value == 'OUT FOR DELIVERY') {
                        <div class="row">
                            <div class="col-12">
                                @if (outForDeliveryOrders.length ==0) {
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    style="min-height: 600px;">
                                    <div>
                                        <img src="../../../assets/no-order.svg" alt="icon">
                                    </div>
                                    <div>
                                        <h3 class="text-muted">No order for out for delivery!</h3>
                                    </div>
                                </div>
                                }@else {
                                <table class="table order-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Order No
                                            </th>
                                            <th>
                                                Created at
                                            </th>
                                            <th>
                                                Elapsed time
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (item of outForDeliveryOrders; track $index) {
                                        <tr>
                                            <td>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-items-center" style="column-gap: 5px;">
                                                        <span>{{item.order_number}}</span>
                                                        @if (item.updated_by ) {
                                                        <span class="ml-3 badge rounded-pill bg-danger">Modified</span>
                                                        }
                                                    </div>
                                                    <span style="cursor: pointer;"
                                                        (click)="openOrderDetailsDialog(item)">click here to get
                                                        details...</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span>{{item.created_at_as_date|date:'medium'}}</span>
                                            </td>
                                            <td>
                                                <span
                                                    style="color: var(--bs-danger-text-emphasis);">{{item.elapsed_time|async}}</span>
                                            </td>
                                            <td>
                                                @if (item.customer) {
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">person</span>
                                                    <span
                                                        style="color: var(--bs-info-text-emphasis);">{{item.customer.name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">phone</span>
                                                    <span>{{item.customer.phone}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="gap: 5px;">
                                                    <span class="material-icons">place</span>
                                                    <span>{{item.address_as_string}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="gap: 5px;">
                                                    <span class="material-icons">map</span>
                                                    <span>{{item.branch.name}}</span>
                                                </div>
                                                }
                                            </td>
                                            <td class="fw-bolder">
                                                {{item.total_as_string}}
                                            </td>
                                            <td class="text-end">
                                                <button (click)="onStatusChangeClick(item)"
                                                    class="btn btn-sm btn-outline-success"
                                                    type="button">Delivered?</button>
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <table class="table table-borderless w-100 m-0">
                                    <tbody>
                                        <tr>
                                            <td class="w-100 text-center">
                                                <button type="button" (click)="onStartAfterClick(item)"
                                                    class="btn btn-sm btn-outline-secondary">Load
                                                    more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        }@else if ( item.value == 'DELIVERED') {
                        <div class="row">
                            <div class="col-12">
                                @if (deliveredOrders.length ==0) {
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    style="min-height: 600px;">
                                    <div>
                                        <img src="../../../assets/no-order.svg" alt="icon">
                                    </div>
                                    <div>
                                        <h3 class="text-muted">No orders delivered today!</h3>
                                    </div>
                                </div>
                                }@else {
                                <table class="table order-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Order No
                                            </th>
                                            <th>
                                                Branch
                                            </th>
                                            <th>
                                                Created at
                                            </th>
                                            <th>
                                                Delivered at
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (item of deliveredOrders; track $index) {
                                        <tr>
                                            <td>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-items-center" style="column-gap: 5px;">
                                                        <span>{{item.order_number}}</span>
                                                        @if (item.updated_by ) {
                                                        <span class="ml-3 badge rounded-pill bg-danger">Modified</span>
                                                        }
                                                    </div>
                                                    <span style="cursor: pointer;"
                                                        (click)="openOrderDetailsDialog(item)">click here to get
                                                        details...</span>
                                                </div>
                                            </td>
                                            <td>
                                                {{ item.branch.name }}
                                            </td>
                                            <td>
                                                <span> {{ item.created_at_as_date|date:'medium' }} </span>
                                            </td>
                                            <td>
                                                <span>{{ item.delivered_at_as_date|date:'medium' }}</span>
                                            </td>
                                            <td>
                                                @if (item.customer) {
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">person</span>
                                                    <span
                                                        style="color: var(--bs-info-text-emphasis);">{{item.customer.name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">phone</span>
                                                    <span>{{item.customer.phone}}</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="gap: 5px;">
                                                    <span class="material-icons">place</span>
                                                    <span>{{item.address_as_string}}</span>
                                                </div>
                                                }
                                            </td>
                                            <td class="fw-bolder">
                                                {{item.total_as_string}}
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <table class="table table-borderless w-100 m-0">
                                    <tbody>
                                        <tr>
                                            <td class="w-100 text-center">
                                                <button type="button" (click)="onStartAfterClick(item)"
                                                    class="btn btn-sm btn-outline-secondary">Load
                                                    more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        }
                    </mat-tab>
                    }
                </mat-tab-group>
                }@else {
                <mat-tab-group (selectedTabChange)="onTabChange($event,2)">
                    @for (item of tabLabelsTakeAway; track $index) {
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span [matBadge]="item.count" matBadgeOverlap="false">{{item.label}}</span>
                        </ng-template>
                        @if ( item.value=='NEW' ) {
                        <div class="row">
                            <div class="col-12">
                                @if (newOrdersTakeAway.length ==0) {
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    style="min-height: 600px;">
                                    <div>
                                        <img src="../../../assets/no-order.svg" alt="icon">
                                    </div>
                                    <div>
                                        <h3 class="text-muted">No new orders</h3>
                                    </div>
                                </div>
                                }@else {
                                <table class="table order-table mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                Order No
                                            </th>
                                            <th>
                                                Branch
                                            </th>
                                            <th>
                                                Created at
                                            </th>
                                            <th>
                                                Elapsed time
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (item of newOrdersTakeAway; track $index) {
                                        <tr>
                                            <td>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-items-center" style="column-gap: 5px;">
                                                        <span>{{item.order_number}}</span>
                                                        @if (item.updated_by ) {
                                                        <span class="ml-3 badge rounded-pill bg-danger">Modified</span>
                                                        }
                                                    </div>
                                                    <span style="cursor: pointer;"
                                                        (click)="openOrderDetailsDialog(item)">click here to get
                                                        details...</span>
                                                </div>
                                            </td>
                                            <td>
                                                {{ item.branch.name }}
                                            </td>
                                            <td>
                                                <div class="d-flex flex-column" style="gap: 10px;">
                                                    <div>
                                                        <span>{{item.created_at_as_date|date:'medium'}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    style="color: var(--bs-danger-text-emphasis);">{{item.elapsed_time|async}}</span>
                                            </td>
                                            <td>
                                                @if (item.customer) {
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">person</span>
                                                    <span
                                                        style="color: var(--bs-info-text-emphasis);">{{item.customer.name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">phone</span>
                                                    <span>{{item.customer.phone}}</span>
                                                </div>
                                                }
                                            </td>
                                            <td class="fw-bolder">
                                                {{item.total_as_string}}
                                            </td>
                                            <td class="text-end">
                                                <button (click)="onStatusChangeClick(item,2)"
                                                    class="btn btn-sm btn-outline-success" type="button">Ready?</button>
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <table class="table table-borderless w-100 m-0">
                                    <tbody>
                                        <tr>
                                            <td class="w-100 text-center">
                                                <button type="button" (click)="onStartAfterClick(item,2)"
                                                    class="btn btn-sm btn-outline-secondary">Load
                                                    more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        } @else if ( item.value == 'READY') {
                        <div class="row">
                            <div class="col-12">
                                @if (readyOrdersTakeAway.length == 0) {
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    style="min-height: 600px;">
                                    <div>
                                        <img src="../../../assets/no-order.svg" alt="icon">
                                    </div>
                                    <div>
                                        <h3 class="text-muted">No ready orders found!</h3>
                                    </div>
                                </div>
                                }@else {
                                <table class="table order-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Order No
                                            </th>
                                            <th>
                                                Branch
                                            </th>
                                            <th>
                                                Created at
                                            </th>
                                            <th>
                                                Elapsed time
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (item of readyOrdersTakeAway; track $index) {
                                        <tr>
                                            <td>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-items-center" style="column-gap: 5px;">
                                                        <span>{{item.order_number}}</span>
                                                        @if (item.updated_by ) {
                                                        <span class="ml-3 badge rounded-pill bg-danger">Modified</span>
                                                        }
                                                    </div>
                                                    <span style="cursor: pointer;"
                                                        (click)="openOrderDetailsDialog(item)">click here to get
                                                        details...</span>
                                                </div>
                                            </td>
                                            <td>
                                                {{ item.branch.name }}
                                            </td>
                                            <td>
                                                <div class="d-flex flex-column" style="gap: 10px;">
                                                    <div>
                                                        <span>{{item.created_at_as_date|date:'medium'}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    style="color: var(--bs-danger-text-emphasis);">{{item.elapsed_time|async}}</span>
                                            </td>
                                            <td>
                                                @if (item.customer) {
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">person</span>
                                                    <span
                                                        style="color: var(--bs-info-text-emphasis);">{{item.customer.name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">phone</span>
                                                    <span>{{item.customer.phone}}</span>
                                                </div>
                                                }
                                            </td>
                                            <td class="fw-bolder">
                                                {{item.total_as_string}}
                                            </td>
                                            <td class="text-end">
                                                <button (click)="onStatusChangeClick(item,2)"
                                                    class="btn btn-sm btn-outline-success" type="button"> Delivered
                                                </button>
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <table class="table table-borderless w-100 m-0">
                                    <tbody>
                                        <tr>
                                            <td class="w-100 text-center">
                                                <button type="button" (click)="onStartAfterClick(item,2)"
                                                    class="btn btn-sm btn-outline-secondary">Load
                                                    more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        }@else if ( item.value == 'DELIVERED') {
                        <div class="row">
                            <div class="col-12">
                                @if (deliveredOrdersTakeAway.length ==0) {
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    style="min-height: 600px;">
                                    <div>
                                        <img src="../../../assets/no-order.svg" alt="icon">
                                    </div>
                                    <div>
                                        <h3 class="text-muted">No orders delivered today!</h3>
                                    </div>
                                </div>
                                }@else {
                                <table class="table order-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Order No
                                            </th>
                                            <th>
                                                Branch
                                            </th>
                                            <th>
                                                Created at
                                            </th>
                                            <th>
                                                Delivered at
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @for (item of deliveredOrdersTakeAway; track $index) {
                                        <tr>
                                            <td>
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex align-items-center" style="column-gap: 5px;">
                                                        <span>{{item.order_number}}</span>
                                                        @if (item.updated_by ) {
                                                        <span class="ml-3 badge rounded-pill bg-danger">Modified</span>
                                                        }
                                                    </div>
                                                    <span style="cursor: pointer;"
                                                        (click)="openOrderDetailsDialog(item)">click here to get
                                                        details...</span>
                                                </div>
                                            </td>
                                            <td>
                                                {{ item.branch.name }}
                                            </td>
                                            <td>
                                                <span> {{ item.created_at_as_date|date:'medium' }} </span>
                                            </td>
                                            <td>
                                                <span>{{ item.delivered_at_as_date|date:'medium' }}</span>
                                            </td>
                                            <td>
                                                @if (item.customer) {
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">person</span>
                                                    <span
                                                        style="color: var(--bs-info-text-emphasis);">{{item.customer.name}}</span>
                                                </div>
                                                <div class="d-flex align-items-center mb-1" style="gap: 5px;">
                                                    <span class="material-icons">phone</span>
                                                    <span>{{item.customer.phone}}</span>
                                                </div>
                                                }
                                            </td>
                                            <td class="fw-bolder">
                                                {{item.total_as_string}}
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                                <table class="table table-borderless w-100 m-0">
                                    <tbody>
                                        <tr>
                                            <td class="w-100 text-center">
                                                <button type="button" (click)="onStartAfterClick(item,2)"
                                                    class="btn btn-sm btn-outline-secondary">Load
                                                    more</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                        }
                    </mat-tab>
                    }
                </mat-tab-group>
                }
            </div>
        </div>
    </div>
    <hr style="border: 0;">
    <hr style="border: 0;">
    <hr style="border: 0;">
</div>

@if (ordersInRealTime$|async) {}
@if (req$|async) {}
@if (permission$|async) {}