<div class="container-fluid" style="max-width: 1500px;">
    @if (form) {
    <div class="row" [formGroup]="form">
        <div class="col-12 col-md-12">
            <div class="card w-100 border-0 mat-elevation-z1">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Start date</mat-label>
                                <input [max]="dateConfig.maxDate" (dateInput)="onChangeInStartDate($event)"
                                    formControlName="start_date" class="w-100" matInput [matDatepicker]="picker">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker class="w-100" #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>End date</mat-label>
                                <input [max]="dateConfig.maxDate" [min]="dateConfig.minDate" formControlName="end_date"
                                    class="w-100" matInput [matDatepicker]="picker1">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker class="w-100" #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Branches</mat-label>
                                <mat-select formControlName="branch_ids" multiple="true">
                                    @for (food of branches; track food) {
                                    <mat-option [value]="food.id">{{food.name}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div
                            class="col-12 col-md-3 d-flex justify-content-between align-items-center flex-column flex-md-row">
                            <mat-checkbox (change)="onAllBranches($event)">All Branches</mat-checkbox>
                            <button (click)="onSearchClick()" type="button"
                                class="btn btn-sm btn-outline-primary w-50">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    }
    <div class="row">
        <div class="col-12">
            <div class="card w-100 border-0 mat-elevation-z1">
                <div class="card-header bg-white">
                    <h2 class="m-0">{{titleConfig.title}}</h2>
                    <h4 class="text-muted m-0"> {{titleConfig.subTitle}} </h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        @if (fetchStatus.chartData) {
                        @if(chartData){
                        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
                            <div class="card w-100 h-100">
                                <div class="card-body">
                                    @if (chartData) {
                                    <p-chart [type]="chartType" [data]="chartData" [options]="chartOptions" />
                                    }@else {
                                    <div class="d-flex justify-content-center align-items-center h-100">
                                        <h2 class="text-muted m-0">No data found</h2>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }@else {
                        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
                            <p class="m-o text-muted">No data to display</p>
                        </div>
                        }
                        }@else {
                        <div class="col-12 col-md-6">
                            <div class="d-flex justify-content-center align-items-center"
                                style="height: 20rem;flex: 1 1 0;">
                                <mat-spinner [diameter]="25"></mat-spinner>
                            </div>
                        </div>
                        }
                        @if (fetchStatus.basicInfo) {
                        <div class="col-12 col-md-6">
                            @for (row of cardValues; track $index) {
                            <div class="row">
                                @for (col of row; track $index) {
                                @if (col.coloCount) {
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body d-flex justify-content-center align-items-center"
                                            style="column-gap: 10px;">
                                            <div class="bg-grey">
                                                <span [ngStyle]="col.style" class="material-icons">{{col.icon}}</span>
                                            </div>
                                            <div>
                                                <h2 class="text-dark m-0">{{col.value}}</h2>
                                                <h4 class="m-0 text-muted">{{col.title}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                }@else {<div class="col-6">
                                    <div class="card">
                                        <div class="card-body d-flex justify-content-center align-items-center"
                                            style="column-gap: 10px;">
                                            <div class="bg-grey">
                                                <span [ngStyle]="col.style" class="material-icons">{{col.icon}}</span>
                                            </div>
                                            <div>
                                                <h2 class="text-dark m-0">{{col.displayValue}}</h2>
                                                <h4 class="m-0 text-muted">{{col.label}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                }
                            </div>
                            <br>
                            }
                        </div>
                        }@else {
                        <div class="col-12 col-md-6">
                            <div class="d-flex justify-content-center align-items-center"
                                style="height: 20rem; flex:1 1 0">
                                <mat-spinner [diameter]="25"></mat-spinner>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

@if (req$|async) {}