<div class="d-block position-relative" style="height: 85vh;">
    @if (profile) {
    <div style="max-width: 800px;width: 800px;position: absolute;left: 50%;top: 20%;
            -ms-transform: translate(-50%, -50%);max-height: 200px;
            transform: translate(-50%, -50%);">
        <div class="card mat-elevation-z1 border-0">
            <div class="card-header">
                <h3 class="mb-0">{{profile.name}}</h3>
                <h5 class="mb-0">{{profile.email}}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4 d-flex align-items-center">
                        <div style="position: relative;" class="img-div">
                            <img [src]="imgUrl" alt="profileImg">
                            <input type="file" (change)="onChangeInImage($event)" accept="image/*"
                                style="display: none;" #profileFile>
                            <div class="position-absolute d-flex"
                                style="top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);">
                                <span (click)="onDeleteImg()" style="cursor: pointer;"
                                    class="material-icons text-danger" title="Remove image">delete</span>
                                <span (click)="profileFile.click()" style="cursor: pointer;"
                                    class="material-icons text-warning" title="Update image">edit</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-8" [formGroup]="form">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Name</mat-label>
                                    <input type="text" matInput formControlName="name" placeholder="Ex. John">
                                    @if (f && f['name'].hasError('invalid')) {
                                    <mat-error>Please enter a valid password</mat-error>
                                    }
                                    @if (f && f['name'].hasError('required')) {
                                    <mat-error>Field required</mat-error>
                                    }
                                    @if (f && f['name'].hasError('minlength')) {
                                    <mat-error>Minimum 3 charector rewuired</mat-error>
                                    }
                                    @if (f && f['name'].hasError('maxlength')) {
                                    <mat-error>Max 50 charectors allwoed</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Email</mat-label>
                                    <input type="email" matInput formControlName="email">
                                    @if (f && f['email'].hasError('invalid')) {
                                    <mat-error>Please enter a valid password</mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button class="btn btn-outline-success btn-sm" (click)="onUpdateClick()" type="button">Update</button>
            </div>
        </div>
    </div>
    }@else {
    <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
        <span>Profile not found</span>
    </div>
    }

</div>

@if (req$|async) {}