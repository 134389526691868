export const FirebaseCollection = {
  USERS: 'users',
  ROLES: 'roles',
  VENDORS: 'vendors',
  DELIVERY_AREAS: 'delivery_areas',
  BRANCHES: 'branches',
  VENDOR_REFS: 'vendor_refs',
  CUSTOMERS: 'customers',
  ORDERS: 'orders',
  ORDER_REFS: 'order_refs',
};

export const OrderStatus = {
  NEW: 'new'.toUpperCase(),
  READY: 'ready'.toUpperCase(),
  ASSIGNED: 'assigned'.toUpperCase(),
  PICKED_UP: 'picked_up'.toUpperCase(),
  OUT_FOR_DELIVERY: 'out for delivery'.toUpperCase(),
  DELIVERED: 'delivered'.toUpperCase(),
  CANCELED: 'canceled'.toUpperCase(),
};

export const OrderMode = {
  DELIVERY: 'delivery'.toUpperCase(),
  TAKE_AWAY: 'take_away'.toUpperCase(),
};

export const ERROR_CODES_CUSTOM = {
  PERMISSION_DENIED: 'PERMISSION_DENIED',
};

export const FirebaseDocFields = {
  ASSIGNED_AT: 'assigned_at',
  STATUS: 'status',
  VENDOR: 'vendor',
  ORDER_MODE: 'order_mode',
  BRANCH_ID: 'branch_id',
};
