import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import {
  BehaviorSubject,
  interval,
  map,
  Observable,
  of,
  startWith,
  Subject,
} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly isLoading_ = new BehaviorSubject<boolean>(false);
  // Observable stream for components to subscribe
  public readonly isLoading$ = this.isLoading_.asObservable();

  private readonly isPageLoaded_ = new Subject<boolean>();
  // Observable stream for components to subscribe
  public readonly isPageLoaded$ = this.isPageLoaded_.asObservable();

  private readonly msg_ = new BehaviorSubject<any>(null);
  public readonly notification$ = this.msg_.asObservable();

  constructor(
    private readonly messageService: MessageService,
    private readonly _snackBar: MatSnackBar
  ) {}

  public showMsg(s: string, title: string, detail: string) {
    this.messageService.add({
      severity: s,
      summary: title,
      detail: detail,
    });
  }

  public showLoader() {
    setTimeout(() => this.isLoading_.next(true));
  }

  public hideLoader() {
    setTimeout(() => this.isLoading_.next(false));
  }

  public makePageLoaded() {
    this.isPageLoaded_.next(true);
  }

  public showSnackBar(message: string, action: string = 'Close') {
    this._snackBar.open(message, action, { duration: 5 * 1000 });
  }

  public handleHttpError(e: any, msg: string) {
    console.log('HTTP error --> ', e);
    if (e instanceof HttpErrorResponse) {
      msg = e.error.msg || msg;
    }
    this.showMsg('error', 'Error', msg);
    return of(null);
  }

  public getElapsedTime(date1: Date): Observable<string> {
    let date: any = null;
    if (date1 instanceof Date) {
      date = date1;
    } else {
      date = new Date(date1);
    }
    if (date == 'Invalid Date') {
      return of('00:00:00');
    }
    return interval(1000).pipe(
      startWith(0),
      map(() => {
        const now = new Date();
        const diff = now.getTime() - date.getTime();

        const hours = Math.floor(diff / (1000 * 60 * 60))
          .toString()
          .padStart(2, '0');
        const minutes = Math.floor((diff / (1000 * 60)) % 60)
          .toString()
          .padStart(2, '0');
        const seconds = Math.floor((diff / 1000) % 60)
          .toString()
          .padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
      })
    );
  }

  public sendNotification(msg: any) {
    this.msg_.next(msg);
  }
}
