<div style="height: 100vh;width: 100vw;gap: 10px;" class="d-flex justify-content-center align-items-center flex-column">
    <div class="card border-0 rounded p-2 mat-elevation-z1" style="min-width: 600px;">
        <div [formGroup]="form" class="card-body border-0 pb-0">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" placeholder="Your email">
                        <mat-icon matSuffix>email</mat-icon>
                        @if (f && f['email'].hasError('required')) {
                        <mat-error>Field required</mat-error>
                        }@else if(f && f['email'].hasError('email')) {
                        <mat-error>Invalid email</mat-error>
                        }@else {
                        <mat-error>Invalid email</mat-error>
                        }
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="pwd" placeholder="Your password">
                        <mat-icon matSuffix>password</mat-icon>
                        @if (f && f['pwd'].hasError('required')) {
                            <mat-error>Field required</mat-error>
                            }@else if(f && f['pwd'].hasError('invalid')) {
                            <mat-error>Invalid email/password</mat-error>
                            }@else {
                            <mat-error>Invalid password</mat-error>
                            }
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-footer pt-0 border-0 bg-white d-flex justify-content-end">
            <button (click)="onSignInClick()" type="button" class="btn btn-md btn-outline-primary">Sign in</button>
        </div>
    </div>
    <div>
        <button class="btn btn-sm" type="button">Forgot password?</button>
    </div>
</div>