import { Component, inject, OnInit } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChartModule, UIChart } from 'primeng/chart';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { AsyncPipe, NgStyle } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { catchError, filter, finalize, map, Observable, of, tap } from 'rxjs';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule,
    NgStyle,
    AsyncPipe,
    ReactiveFormsModule,
    ChartModule,
    MatProgressSpinnerModule,
  ],
  providers: [],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  private readonly authService: AuthService = inject(AuthService);
  private readonly commonService: CommonService = inject(CommonService);
  private readonly fb: FormBuilder = inject(FormBuilder);
  public req$: Observable<any> = of([]);
  public form: FormGroup = this.fb.group({});
  public chartData: any = null;
  public chartOptions: any = null;
  public fetchStatus: any = { basicInfo: false, chartData: false };
  public titleConfig: any = { subTitle: 'Sales Report', title: 'Dashboard' };
  public dateConfig = {
    maxDate: new Date(),
    minDate: new Date(),
  };
  public chartType: any = 'bar';
  constructor() {
    this.form = this._buildForm();
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.chartOptions = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
    this.fetchStatus.chartData = true;
  }
  ngOnInit(): void {
    this._getData({ init: true });
  }
  public branches: any[] = [];
  public cardValues: any[] = [];

  private _chunkArray(arr: any[], chunkSize: number = 2) {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  private _getData(paramsFn: any = {}) {
    const {
      start_date = new Date().toISOString(),
      end_date = new Date().toISOString(),
      branch_ids = [],
      init = false,
    } = paramsFn;
    this.commonService.showLoader();
    const url = '/api/cc-vendor/dashboard/init';
    const data = {
      start_date,
      end_date,
      branch_ids: branch_ids,
      init,
    };
    this.req$ = this.authService.postData(url, data).pipe(
      finalize(() => this.commonService.hideLoader()),
      catchError((error) =>
        this.commonService.handleHttpError(error, 'Error fetching data')
      ),
      filter((e1) => !!e1),
      map((d: any) => d.data),
      tap((data) => {
        const { boxData, branches, params, chart } = data;
        if (boxData) {
          this.cardValues = this._chunkArray(boxData, 2);
          console.log('this.cardValues', this.cardValues);
        } else {
          this.cardValues = [];
        }
        if (branches && this.branches.length == 0) {
          this.branches = branches;
          this.f['branch_ids'].setValue(branches.map((b: any) => b.id));
        }
        if (params) {
          const { subTitle, ...restParams } = params;
          this.form.patchValue(restParams);
          this.titleConfig.subTitle = subTitle;
        }
        if (chart) {
          const { data, labels, label, type } = chart;
          this.chartType = type;
          if (data && data.length > 0) {
            this.chartData = {
              labels: labels, //['Q1', 'Q2', 'Q3', 'Q4'],
              datasets: [
                {
                  label: label, // 'Sales',
                  data: data, //[540, 325, 702, 620],
                  backgroundColor: [
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                  ],
                  borderColor: [
                    'rgb(255, 159, 64)',
                    'rgb(75, 192, 192)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)',
                  ],
                  borderWidth: 1,
                },
              ],
            };
          } else {
            this.chartData = null;
          }
        }
        this.fetchStatus.basicInfo = true;
      })
    );
  }

  private _buildForm() {
    const form = this.fb.group({
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      branch_ids: [[], [Validators.required]],
    });
    return form;
  }

  public onSearchClick() {
    console.log('form value : ', this.form.value);
    console.log('Errors : ', this.form.errors);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.commonService.showMsg(
        'error',
        'Error',
        'Please select start date, end date and branch'
      );
      return;
    }
    const { start_date, end_date } = this.form.value;
    const branch_ids = this.f['branch_ids'].getRawValue();
    console.log('Branch ids', branch_ids);
    this._getData({ start_date, end_date, branch_ids });
  }

  public get f() {
    return this.form.controls;
  }
  public onAllBranches(event: MatCheckboxChange) {
    console.log('event', event);
    const { checked } = event;
    if (checked) {
      this.f['branch_ids'].setValue(this.branches.map((b: any) => b.id));
      this.f['branch_ids'].disable();
    } else {
      this.f['branch_ids'].setValue([]);
      this.f['branch_ids'].enable();
    }
  }

  public onChangeInStartDate($event: any) {
    const { value } = $event;
    this.dateConfig.minDate = value;
  }
}
