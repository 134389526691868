<div class="d-flex flex-column" style="gap: 5px;">
    <div class="d-flex">
        <span class="custom-title">ORDER DETAILS</span>
        <span class="text-muted fst-italic fw-light">
            {{'#'+order.order_number}}
        </span>
    </div>
    <div>
        <span class="custom-badge"> {{order.order_mode}} </span>
    </div>
</div>
<hr>
<div style="overflow: auto;padding: 10px;">
    <div class="row">
        <div class="col-12 col-md-5">
            <div class="row">
                <div class="col-12">
                    <div class="card border-0 mat-elevation-z1">
                        <div class="card-header d-flex justify-content-between bg-white">
                            <div class="d-flex align-items-center" style="column-gap: 5px;color: var(--bs-gray-600);">
                                <span class="material-icons">person</span>
                                <span style="color: var(--bs-gray-600);"> {{order.customer.name}} </span>
                            </div>
                            <div class="d-flex align-items-center" style="column-gap: 5px;color: var(--bs-gray-600);">
                                <span class="material-icons">phone</span>
                                <span style="color: var(--bs-gray-600);"> {{order.customer.phone}} </span>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-center" style="column-gap: 5px;">
                            <span class="material-icons">home</span>
                            <span> {{order.address_as_string}} </span>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between bg-white">
                            <span class="text-muted"> {{order.branch_name}} </span>
                            <span class="material-icons text-muted">place</span>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 d-flex flex-column align-items-center" style="row-gap: 5px;">
                    <mat-chip title="order status">
                        <span> {{order.status}} </span>
                    </mat-chip>
                    <mat-chip title="order created at">
                        <span> {{order.created_at_as_date|date:'medium'}} </span>
                    </mat-chip>
                    <mat-chip title="order created by">
                        <span> {{order.created_by.name}} </span>
                    </mat-chip>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-7">
            <div class="card card-item-cart mat-elevation-z1 border-0">
                @if (orderNotes) {
                <div class="card-header bg-white">
                    <p class="m-0" style="font-style: italic;">{{orderNotes}}</p>
                </div>
                }
                <div class="card-body p-0">
                    <table class="table item-table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th class="text-center">Qty</th>
                                <th class="text-center">Sub total</th>
                                <th class="text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(item of cartItems;track item;let index=$index){
                            <tr>
                                <td class="text-start">
                                    <ul class="parent">
                                        <li class="container-1">
                                            <p class="d-flex justify-content-start align-items-center"
                                                style="gap: 10px">
                                                <span style="cursor: pointer">{{
                                                    item.name + " - " }}
                                                    <small style="font-size: 11px; font-weight: bold">{{
                                                        "(" +
                                                        item.unit_price +
                                                        "
                                                        QAR)"
                                                        }}</small>
                                                </span>
                                                <span class="material-icons text-muted" title="Add item notes"
                                                    style="cursor: pointer; font-size: 17px">note_add</span>
                                            </p>
                                            <ul>
                                                @for(subItem of item.addOns;track subItem){
                                                <li>
                                                    <p class="d-flex justify-content-start align-items-center"
                                                        style="gap: 10px; padding-left: 5px">
                                                        <span>{{
                                                            subItem.name +
                                                            " (" +
                                                            subItem.unit_price +
                                                            " QAR)" +
                                                            " -
                                                            " +
                                                            subItem.qty
                                                            }}</span>
                                                        @if (subItem.type == 'rm') {
                                                        <span class="material-icons text-muted"
                                                            style="cursor: pointer; font-size: 18px">cancel</span>
                                                        }
                                                    </p>
                                                </li>
                                                } @for(note of item.notes;track note;let noteIndex=$index){
                                                <li class="empty-1">
                                                    <p class="d-flex justify-content-start align-items-center"
                                                        style=" gap: 10px;padding-left: 5px;cursor: pointer;">
                                                        <span>{{note}}</span>
                                                        <span class="material-icons text-muted"
                                                            style="cursor: pointer; font-size: 18px">cancel</span>
                                                    </p>
                                                </li>
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                </td>
                                <td class="text-center">
                                    {{item.qty}}
                                </td>
                                <td class="text-center">
                                    <p>{{ item.itemTotal }}</p>
                                    @if (
                                    item.addOns.length>0
                                    ) {
                                    <p>{{ item.addOnTotal }}</p>
                                    }

                                </td>
                                <td class="text-center">{{ item.total }}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>

                <div class="card-footer">
                    <div class="row">
                        <div class="col-7"></div>
                        <div class="col-5">
                            <table class="table table-borderless total-table mb-0">
                                <tbody>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>{{ order.subTotal||0 }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            @if (discounts.length) {
                            <hr class="m-0" style="border-style: dotted;">
                            }
                            <table class="table table-borderless total-table mb-0">
                                <tbody>
                                    @for (item of discounts; track item) {
                                    <tr>
                                        <td class="text-muted">{{ item.name }}</td>
                                        <td class="text-muted">{{ item.displayAmount }}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                            @if (discounts.length) {
                            <hr class="m-0" style="border-style: dotted;">
                            }
                            <table class="table table-borderless total-table mb-0">
                                <tbody>
                                    @for (item of surcharges; track item) {
                                    <tr>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.displayAmount }}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                            <hr class="m-0">
                            <table class="table table-borderless total-table mb-0">
                                <thead>
                                    <tr>
                                        <th>Total</th>
                                        <th>{{ order.total||0 }}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<hr>
<div class="d-flex justify-content-end">
    <button type="button" class="btn btn-sm btn-outline-secondary" (click)="dialogRef.close()">Close</button>
</div>